@import "~bootstrap/scss/bootstrap";

$color-primary: #4F4E69;
$color-secondary: #79BEF1;

$sidebar-bg-color: #fff !default;
$sidebar-color: #4F4E69 !default;
$sidebar-width: 275px !default;
$highlight-color: $color-secondary !default;

@import '~react-pro-sidebar/dist/scss/styles.scss';


* {
  font-family: 'Poppins', sans-serif;
}

body {
  background-color: #F6FbFF;
}

.color-primary {
  color: $color-primary !important;
}

.color-secondary {
  color: $color-secondary !important;
}

.normal-font {
  font-weight: normal !important;
  font-size: 13px;
}

.form-check-label {
  font-weight: normal !important;
  font-size: 13px;
}
.form-check-input{
  width: 1.2em;
  height: 1.2em;
}
.form-check-input:checked {
  background-color: $color-secondary;
  border-color: $color-secondary;
}

.login-container {
  padding: 0;
  width: 100%;
  background-color: white;
  @media screen and (max-width: 768px) {
    --bs-gutter-x: 0;
  }

  .login-right-container {
    z-index: 1;
    @media screen and (max-width: 768px) {
      background-color: rgba(255, 255, 255, 0.95);
      max-width: 100%;
      padding: 10px 50px;
      min-height: 100vh;
      display: flex;
      align-items: center;
    }
  }

  .login-form-container {
    max-width: 450px;
    z-index: 1;
    @media screen and (max-width: 768px) {
      max-width: 100%;
    }
  }

  .sign-up-container {
    margin-top: 150px;
  }

  .login-img, .login-img > div {
    padding: 0;
    height: 100vh;
    overflow: hidden;
    @media screen and (max-width: 767px) {
      position: absolute;
      top: 0;
      z-index: 0;
      min-height: 100vh;
    }
  }

  .form-control {
    box-shadow: none !important;
    outline: none !important;
    border: none !important;
    border-radius: 0 !important;
    border-bottom: 1px solid rgba(121, 190, 241, 0.3) !important;
    padding-left: 0 !important;
    font-weight: normal !important;
    font-size: 13px;

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #172331;
      font-weight: normal;
      opacity: 0.7;
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #172331;
      font-weight: normal;
      opacity: 0.7;
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: #172331;
      font-weight: normal;
      opacity: 0.7;
    }
  }
  .forgot-pw {
    @extend .color-secondary, .link-custom;
    font-weight: 400 !important;
    font-size: 13px;
    font-style: italic;
    cursor: pointer;
  }
}

.brand-container {
  font-size: 25px;
  font-weight: 600;

  div:first-child {
    @extend .color-primary
  }

  div:last-child {
    @extend .color-secondary
  }
}

.button-custom {
  @extend .normal-font;
  width: 100%;
  padding: 13px 10px;
  background: #79BEF1;
  box-shadow: 0px 8px 24px rgba(121, 190, 241, 0.25);
  border-radius: 14px;
  color: white !important;
  svg, path{
    fill: #FFFFFF;
  }
}

.link-custom {
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}



.header-container {
  background-color: #fff;
  height: 80px;
  color: $color-primary;
  font-weight: 600;
  display: flex;
  align-items: center;
  width: 100%;

  .header-topic {
    font-size: 20px;
  }

  //.dropdown-user-options button {
  //  background-color: transparent !important;
  //  border: none;
  //  padding: 0;
  //}
  //
  //#dropdown-basic {
  //  max-height: 70px;
  //  box-shadow: none !important;
  //}
  //
  //#dropdown-basic::after {
  //  display: none;
  //}
  //
  //.dropdown-menu {
  //  padding: 0;
  //  background: #FFFFFF;
  //  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.05);
  //  border-radius: 8px 0px 8px 8px;
  //  border: none;
  //
  //  .dropdown-item {
  //    padding: 10px 0px;
  //
  //    &:first-child {
  //      border-top-left-radius: 8px;
  //    }
  //
  //    &:last-child {
  //      border-bottom-right-radius: 8px;
  //    }
  //  }
  //}

  @media screen and (max-width: 767px) {
    font-size: 12px;
    .header-topic {
      font-size: 18px;
    }
  }
}

.layout-content-wrapper{
  max-height: calc(100vh - 80px);
  max-width: calc(100vw - 230px);
  overflow-y: auto;
  @media screen and (max-width: 767px) {
    max-width: 100vw;
  }
}

.round-container {
  background-color: #FFFFFF;
  border-radius: 16px;
  align-items: center;
  padding: 20px 13px;
}

.detail-item-container {
  @extend .round-container;
  max-width: 280px;
  width: 280px;

  .icon-container {
    background-color: #F2FAFF;
    padding: 13px 6px;
    border-radius: 8px;
  }

  .detail-container {
    div {
      max-height: 30px;
    }

    div:first-child {
      font-size: 30px;
      font-weight: bold;
      margin-top: -10px;
      padding-top: 5px;
      margin-bottom: 10px;
    }

    div:last-child {
      font-size: 13px;
      font-weight: lighter;
      padding-top: 5px;
    }
  }
}


.file-drop {
  cursor: pointer;
  background-color: #F2FAFF;
  height: 80px;
  border-radius: 5px;

  .file-name {
    margin: 0 10px;
    font-size: 12px;
    white-space: pre-line;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.file-drop-target {
  max-width: 100%;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;

  div {
    pointer-events: none;
    align-items: center;
    margin: auto
  }

  .img-container-upload{
    overflow-x: auto;
    pointer-events: initial;
    div, img{
      pointer-events: initial;
    }
  }
}

.file-drop > .file-drop-target.file-drop-dragging-over-target {
  box-shadow: 0 0 13px 3px #f4f4f4;
  background: rgba(242, 250, 255, 0.1) !important;
}

.react-bootstrap-table {
  min-width: 600px;
}

.job-table-class {
  background-color: white;
  border-radius: 16px 16px 0 0;
  border-color: transparent;
  .job-table-header-class {
    th {
      @extend .normal-font;
      font-weight: bold !important;
      text-align: center;
      height: 50px;
      padding-bottom: 13px;
    }
  }

  .job-table-body-class {
    td {
      @extend .normal-font;
      text-align: center;
      border-bottom: 1px solid #F2FAFF;
      line-height: 30px;
    }
  }

  .expanded-area {
    background-color: #F2FAFF;
    border-radius: 8px;
    padding: 20px 30px;

    .value-area {
      @extend .round-container;
      border-radius: 9px;
    }
  }
}

.table > :not(:first-child) {
  border-top: 2px solid #F2FAFF;
}

.download-column {
  div {
    div {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

.job-detail-container {
  > div {
    text-align: center;
    @media screen and (max-width: 767px) {
      margin-top: 10px;
    }

    div:first-child {
      color: #848484;
      font-weight: 200;
      font-size: 13px;
    }
  }
}

.value-area {
  border: 1px solid $color-secondary;
  border-radius: 8px;
  padding: 10px 11px 0 !important;

  .data-row {
    padding: 5px 10px;

    div:first-child {
      width: 200px;
    }

    div:last-child {
      width: calc(100% - 200px);
    }
  }

  .scrollable-area {
    max-height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .data-main-row {
    border-bottom: 1px solid #e4f1fa;
  }
}

.breadcrumb-custom a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.pro-sidebar-inner {
  min-height: 100vh;
  .pro-sidebar-layout {
    min-height: 100vh;
    .pro-sidebar-header {
      border-bottom: none !important;
    }

    .pro-sidebar-content {
      > .pro-menu {
        min-height: 100%;

        padding-left: 0px !important;
        padding-right: 0px !important;

        > ul {
          min-height: 100%;
          > li {
            font-size: 14px !important;
            margin-top: 0px !important;
            margin-left: 15px;
            border-radius: 5px 0 0 5px;

            > div:first-child{
              border-radius: 5px 0 0 5px;
              padding: 8px 0px 8px 10px;
            }

            &:hover > div:first-child{
              color: $color-secondary !important;
              background-color: #79BEF11A;

              path {
                fill: $color-secondary !important;
              }
            }
          }

          .selected-menu-item{
            color: $color-secondary !important;
            background-color: #79BEF11A;
            path {
              fill: $color-secondary !important;
            }
            > div{
              border-right-style: solid;
              border-right-width: 5px;
              border-right-color:$color-secondary;
            }
          }
        }
        .pro-sub-menu{
          > .pro-inner-item{
            color: #4F4E69 !important;
          }
          > .pro-inner-list-item{
            background-color: white;
            padding-top: 3px;

            ul{
              padding-top: 0;
              padding-bottom: 0;
              li {
                font-size: 14px;
                border-radius: 5px 0 0 5px;

                > div:first-child{
                  border-radius: 5px 0 0 5px;
                  padding: 12px 0px 12px 10px;
                }

                &:hover > div:first-child{
                  color: $color-secondary !important;
                  background-color: #79BEF11A;

                  path {
                    fill: $color-secondary !important;
                  }
                }
              }
            }
            .selected-menu-item{
              color: $color-secondary !important;
              background-color: #79BEF11A;
              border-radius: 8px;
              path {
                fill: $color-secondary !important;
              }
              > div{
                border-right-style: solid;
                border-right-width: 5px;
                border-right-color:$color-secondary;
              }
            }
          }
        }
      }
    }
  }
}


.pro-sidebar-footer{
  border-top: none !important;
  margin-bottom: 20px;
  li{
    margin-left: 15px;
    list-style: none;
    font-size: 14px !important;
    border-radius: 5px 0 0 5px;

    > div{
      padding: 8px 0px 8px 10px;
    }

    &:hover {
      color: $color-secondary !important;
      background-color: #79BEF11A;

      path {
        fill: $color-secondary !important;
      }
    }

    .pro-icon-wrapper{
      margin-right: 10px;
      font-size: 14px;
      width: 35px;
      min-width: 35px;
      height: 35px;
      line-height: 35px;
      text-align: center;
      display: inline-block;
    }
  }
}

.pro-sidebar{
  @media screen and (max-width: 767px) {
    z-index: 1100;
  }
}
//.collapsed {
//  .pro-sidebar-layout {
//    > .pro-menu {
//      padding-left: 10px !important;
//      padding-right: 10px !important;
//
//      > ul {
//        > li {
//          .pro-inner-item:first-child {
//            padding-left: 12px !important;
//          }
//        }
//      }
//    }
//  }
//}


.clients-table-wrapper{
  background: #FFF;
  border-radius: 16px 16px 0 0;
  padding: 20px 25px 0;
  overflow-x: auto;
  .clients-table-button-wrapper{
    position: absolute;
    right: 30px;
    top: -5px;
    svg {
      height: 11px;
      width: 11px;

      path {
        fill: white;
      }
    }
  }

  .expand-icon{
    height: 11px;
    width: 11px;
  }

  .react-bootstrap-table{
    min-width: 680px;
  }

  @media screen and (max-width: 767px) {
    padding-top: 60px;
    .clients-table-button-wrapper{
      top: -10px;
      right: 15px;
    }
  }
}


.expanded-client-area{
  color: $color-primary;
  font-size: 13px;
  font-weight: 200;
  div:first-child{
    min-width: 25%;
    max-width: 25%;
    text-align: left;
  }
  div:nth-child(2){
    min-width: 25%;
    max-width: 25%;
    text-align: left;
  }
  div:nth-child(3){
    min-width: 20%;
    max-width: 20%;
    text-align: left;
  }
}


.cursor-pointer{
 cursor: pointer;
}

.verify-modal {
  @media (min-width: 992px) {
    max-width: 90% !important;
  }
  .modal-body{
    max-height: 80vh;
    overflow-y: scroll;
  }
}

.client-modal {
  @media (min-width: 992px){
    max-width: 650px;
  }

  .modal-content {
    border-radius: 30px;

    .modal-header {
      border-bottom-color: #E9F6FE;
      border-bottom-width: 2px;

      h5 {
        width: 100%;
        display: flex;
        align-items: center;
        color: $color-secondary;

        div:last-child {
          padding: 6px;
          background-color: #79BEF133;
          line-height: 0;
          border-radius: 8px;
        }

        div:first-child {
          min-width: calc(100% - 30px);
          text-align: center;
        }
      }
    }

    .modal-body {
      font-size: 13px;

      .form-control {
        box-shadow: none;
        height: 45px;
        border: none;
        background-color: #F4FAFF;
        border-radius: 14px;
        text-indent: 10px;
        font-size: 12px;

        &::placeholder {
          font-weight: lighter;
          color: #B3B3C5;
          font-size: 12px;
        }
      }
      .form-check label{
        font-size: 13px;
        font-weight: lighter;
      }

      .form-check-input{
        width: 1.15rem;
        height: 1.15rem;
        margin-top: 0;
        border-color: $color-secondary;
        border-width: 0.15em;
      }
    }
  }
}

.no-select-value{
  color: #B3B3C5 !important;
}

.form-list{
  font-size: 13px;

  label{
    color: #848484;
  }
  .form-control {
    @extend .custom-form
  }

  .file-drop{
    min-height: 200px;
    height: unset;

    .img-placeholder{
      margin-left: 10px;
      margin-right: 10px;
      border: 1px solid #79BEF1;
      border-radius: 23px;
      width: 120px;
      height: 120px;
    }

    .delete-icon{
      position:absolute;
      top:-6px;
      right:-6px;
      background-color: #ffcecc;
      padding: 5px 7px;
      border-radius: 10px;
    }
  }
}

.attribute-mapping-headers{
  font-size: 13px;
  font-weight: 600;
  div{
    width: 200px;
    text-align: center;
  }
}

.attribute-mapping-row{
  font-size: 13px;
  border-top: 1px solid #E9F6FE;
  height: 80px;
  div:first-child{
    max-width: 40px;
    min-width: 40px;
  }
  div:nth-child(2){
    width: 180px;
    margin-left: 18px;
  }
  div:nth-child(2){
    width: 180px;
  }
  .form-control{
    @extend .custom-form
  }
}


.custom-form{
  box-shadow: none;
  height: 45px;
  border: none;
  background-color: #F4FAFF;
  border-radius: 14px;
  text-indent: 10px;
  font-size: 12px;

  &::placeholder {
    font-weight: lighter;
    color: #B3B3C5;
    font-size: 12px;
  }
}


.brands-data-container{
  max-width: 400px;

  .brands-top{
    text-align: center;
    div:first-child{
      font-size: 45px;
      font-weight: 700;
    }
    div:last-child{
      font-weight: 100;
      margin-top: -10px;
    }
  }

  .brands-row{
    font-size: 13px;
    border-top: 2px solid #E9F6FE;
    height: 50px;
  }

  .brands-details-container{
    max-height: calc(100vh - 450px);
    overflow-y: auto;
  }
}

.brand-modal{
  max-width: 400px;
}

.attributes-container{
  max-width: max-content;
  .attribute-mapping-row{
    font-size: 13px;
    border-top: 1px solid #E9F6FE;
    height: 70px;
    div:first-child{
      max-width: 40px;
      min-width: 40px;
    }
    div:nth-child(2){
      width: 230px;
      margin-left: 10px;
    }
    div:nth-child(3){
      width: 200px;
      margin-left: 10px;
    }
  }

  .attribute-mapping-headers {
    div {
      width: 200px;
      text-align: center;
    }

    input{
      width: 100%;
    }
  }

  .icons-container{
    @extend .small-icons-container;
  }
}

.small-icons-container{
  background-color: #79BEF133;
  padding: 8px;
  border-radius: 10px;
  max-width: 35px;
  max-height: 35px;
}

.sub-headers{
  font-size: 16px;
}

.catalog-table-wrapper{
  background: #FFF;
  border-radius: 16px 16px 0 0;
  padding: 20px 25px 0;
  overflow-x: auto;

  .small-icons-container{
    padding: 8px;
    &:first-child{
      transform: rotate(180deg);
    }
  }

  .sr-only{
    display: none;
  }

  .table-filter-input{
    @extend .custom-form;
    height: 40px;
    margin: 15px 0;
    width: 100%;
  }

  .form-select{
    @extend .custom-form;
    color: #B3B3C5;
    width: 70px;
    padding: 0 5px 0 2px;
    height: 40px
  }

  .catalog-table-header-class{
    @extend .job-table-header-class;
    vertical-align: baseline;
    border-top: 2px solid #F2FAFF;
    th{
      padding-top: 20px;
    }
  }

  .catalog-table-body-class{
    @extend .job-table-body-class;
    tr td{
      line-height: 60px !important;
    }
    td:first-child{
      padding-left: 0;
    }

    tr:last-child td{
      border-bottom: none;
    }
  }

  .catalog-table-class{
    @extend .job-table-class;
    input[type='checkbox']{
      @extend .form-check-input;
      width: 1.3em;
      height: 1.3em;
      border-radius: 0.25em;
      vertical-align:  middle;
      margin-right: 200px;
      margin-bottom: 6px;
    }

    input[type=checkbox]:indeterminate{
      background-color: $color-secondary;
      border-color: $color-secondary;
    }
  }
}


.product-detail-container{
  .main-container{
    img{
      max-width: 70%;
      max-height: 70%;
      margin: auto;
    }
    .detail-wrapper{
      color: #B3B3C5;
      background-color: #F4FAFF;
      border-radius: 14px;
      max-width: 90%;
    }

    > div{
      min-height: 100%;
      > div{
        min-height: 100%;
      }
    }
  }

  .images-container{
    overflow-x: auto;
    width: 90%;
    margin: auto;
  }

  .img-placeholder{
    margin-left: 15px;
    margin-right: 15px;
    border: 1px solid #79BEF1;
    border-radius: 23px;
    width: 120px;
    height: 120px;
  }

  .form-control{
    box-shadow: none;
    height: 20px;
    border: none;
    background-color: #F4FAFF;
    border-radius: 7px;
    text-indent: 1px;
    font-size: 12px;
    padding-left: 5px;
    max-width: 97.5%;
  }

  .icon-holder{
    position: absolute;
    right: 0;
    width: max-content;
    margin-left: auto;
    > div{
      padding-right: 2.5%;
      background-color: white;
      width: max-content;
      margin-left: auto;
      div{
        box-shadow: 2px 2px 5px 0 rgb(204, 204, 204);
      }
      .edit-icon{
        visibility: hidden;
      }
      .icon-container-2{
        padding: 1px;
        line-height: 17px;
        border-radius: 10px;
        background-color: #79BEF1;
        svg, path {
          fill: white;
        }
      }
      .icon-container-3{
        margin-right: 1px;
        border-radius: 10px;
        svg {
          background-color: transparent;
          width: 20px;
        }
      }
      .icon-container-4{
        @extend .icon-container-2;
        padding: 1px 3px;
      }
    }
  }

  .nav-item{
    width: 25%;
    margin-right: -1px;
    border: none !important;
    button, .disabled{
      margin: auto;
      color: $color-primary;
      border-bottom: 2px solid #E9F6FE;
      width: 100%;
      border-top: none !important;
      border-left: none !important;
      border-right: none !important;
      border-radius: 0 !important;
      white-space: nowrap;
    }
  }

  .nav-tabs {
    border-bottom: none;
  }

  .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus{
    border: none;
    border-bottom: 2px solid #E9F6FE;
  }

  .selected-tab{
    border: none;
    border-bottom: 2px solid $color-secondary !important;
    border-color: $color-secondary !important;
    width: 100%;
    color: $color-secondary !important;
    line-height: 19px;
  }

  .detail-row:hover{
    .edit-icon{
      visibility: visible;
    }
  }

  @media screen and (max-width: 767px) {
    .main-container {
      --bs-gutter-x: 0;

      .detail-wrapper {
        max-width: 100%;
      }
    }

    .img-placeholder{
      width: 80px;
      height: 80px;
      img, div{
        max-width: 77px !important;
        max-height: 77px !important;
      }
    }
  }
}

.attributes-verification-container {
  .pid-holder{
    box-shadow: none;
    border: none;
    background-color: #F4FAFF;
    border-radius: 7px;
    font-size: 13px;
    padding: 15px;
    color: rgb(179, 179, 197);
    @media screen and (max-width: 767px) {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .img-upload .file-drop{
    min-height: 200px;
    height: unset;
    font-size: 13px;
  }
  .img-container-upload{
    @extend .img-container-upload;

    .img-placeholder{
      margin-left: 15px;
      margin-right: 15px;
      border: 1px solid #79BEF1;
      border-radius: 23px;
      width: 91px;
      height: 91px;
      img {
        border-radius: 23px;
        overflow: hidden;
      }
    }

    .delete-icon{
      position:absolute;
      top:-6px;
      right:-6px;
      background-color: #ffcecc;
      padding: 5px 7px;
      border-radius: 10px;
    }
  }
  .verification-table-wrapper {
    overflow-x: auto;

    .verification-table-class {
      @extend .job-table-class;

      input[type='checkbox'] {
        @extend .form-check-input;
        width: 1.3em;
        height: 1.3em;
        border-radius: 0.25em;
        vertical-align: middle;
        margin-bottom: 6px;
      }

      input[type=checkbox]:indeterminate {
        background-color: $color-secondary;
        border-color: $color-secondary;
      }
    }

    .verification-table-header-class {
      @extend .job-table-header-class;
    }

    .verification-table-body-class {
      @extend .job-table-body-class;

      td, td div {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .form-check-green{
    background-color: #008000 !important;
    border-color: #008000 !important;
  }

  .form-check-red{
    background-image: url("assets/Close-white.svg");
    background-color: red !important;
    border-color: red !important;
    background-size: 11px !important;
  }
}

.loading-text{
  margin-left: -40%;
  @media screen and (max-width: 400px) {
    margin-left: -45%;
  }
}

.img-enhancement-container{
  .form-control{
    @extend .custom-form;
    max-width: 400px;
  }
  .submit-btn button{
    width: 160px;
  }

  .processed-img-container{
    min-height: 100px;
    margin-top: 45px;
  }
  .attribute-mapping-row{
    justify-content: space-evenly;
    height: 60px;
    div{
      width: 50%;
      max-width: unset;
      min-width: unset;
    }
  }
  .attribute-mapping-container{
    max-width: 500px;
    margin: auto;
  }
}